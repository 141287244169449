body {
  font-family: 'Proxima Nova';
}
/* Used for cluster marker */
.cluster > div {
  font-size: 22px !important;
  font-family: 'Proxima Nova' !important;
  font-weight: normal !important;
  line-height: 56px !important;
}

@-webkit-keyframes argh-my-eyes {
  0%   { background-color: #e63b3b; }
  49% { background-color: #e63b3b; }
  50% { background-color: #1f2232; }
  99% { background-color: #1f2232; }
  100% { background-color: #e63b3b; }
}
@-moz-keyframes argh-my-eyes {
  0%   { background-color: #e63b3b; }
  49% { background-color: #e63b3b; }
  50% { background-color: #1f2232; }
  99% { background-color: #1f2232; }
  100% { background-color: #e63b3b; }
}
@keyframes argh-my-eyes {
  0%   { background-color: #e63b3b; }
  49% { background-color: #e63b3b; }
  50% { background-color: #1f2232; }
  99% { background-color: #1f2232; }
  100% { background-color: #e63b3b; }
}