@font-face {
  font-family: 'Proxima Nova';
  src: url('../../assets/fonts/proxima_nova_regular.otf') format('opentype');
}

@font-face {
  font-family: 'Proxima Nova SemiBold';
  src: url('../../assets/fonts/proxima_nova_semibold.otf') format('opentype');
}

@font-face {
  font-family: 'Proxima Nova Bold';
  src: url('../../assets/fonts/proxima_nova_bold.otf') format('opentype');
}

@font-face {
  font-family: 'Proxima Nova Light';
  src: url('../../assets/fonts/proxima_nova_light.otf') format('opentype');
}

@font-face {
  font-family: 'incident-co';
  src:  url('../../assets/fonts/incident-co.eot?hwt2d0');
  src:  url('../../assets/fonts/incident-co.eot?hwt2d0#iefix') format('embedded-opentype'),
  url('../../assets/fonts/incident-co.ttf?hwt2d0') format('truetype'),
  url('../../assets/fonts/incident-co.woff?hwt2d0') format('woff'),
  url('../../assets/fonts/incident-co.svg?hwt2d0#incident-co') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

*:focus {
  outline: none;
}

html,
body {
  width: 100%;
  height: 100%;
  background-color: #191b23;
}

#root {
  width: 100%;
  height: 100%;
}

.bg-gray {
  background: #eeeeee;
}

.bg-dark-gray {
  background: #2a2c34;
}

.bg-dark-black {
  background: #1b1e26;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'incident-co' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-bell:before {
  content: "\e900";
}
.icon-carret:before {
  content: "\e901";
}
.icon-chat:before {
  content: "\e902";
}
.icon-chevron-left:before {
  content: "\e903";
}
.icon-chevron-right:before {
  content: "\e904";
}
.icon-circle:before {
  content: "\e905";
}
.icon-close:before {
  content: "\e906";
}
.icon-exclamation:before {
  content: "\e907";
}
.icon-path:before {
  content: "\e908";
}
.icon-pin .path1:before {
  content: "\e909";
  color: rgb(0, 0, 0);
  opacity: 0.345;
}
.icon-pin .path2:before {
  content: "\e90a";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.5;
}
.icon-pin .path3:before {
  content: "\e90b";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.8;
}
.icon-play-audio:before {
  content: "\e90c";
}
.icon-report:before {
  content: "\e90d";
}
.icon-send:before {
  content: "\e90e";
}

.elementToFadeInAndOut {
  animation: fadeinout 2s linear infinite;
}

@keyframes fadeinout {
  0%,100% { opacity: 0; }
  20% { opacity: 1; }
  40% { opacity: 1; }
  60% { opacity: 1; }
  80% { opacity: 1; }
}
